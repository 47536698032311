import React, { useContext, useRef, useEffect, useState, useMemo } from 'react'
import { useNavigate, Navigate } from 'react-router-dom';
import XIfame from '../../components/XIframe/XIfame';
import Context from '../../context';
import useTimer from '../../hooks/useTimer';
import { getDistanceFrom, getSizeFromGrid } from './../../helpers/tools.helpers'
import { POSITION } from '../../constants';

//Scss
import './OpinionTest.scss'

import RightBox from '../../components/RigthBox/RightBox';
import { saveUserInfo } from '../../api/api';
import useGridLayout from '../../hooks/useGridLayout';
import { opinionTest, opinionTestWithoutUser } from '../../layouts';
import Xbutton from '../../components/XButton/Xbutton';
import InfoUserForm from '../../components/InfoUserForm/InfoUserForm';

const WITHOUT_TIME = process.env.REACT_APP_WITHOUT_TIME

const FirstActivity = () => {
    let navigate = useNavigate();

    const { user, evaluation, showFormInfo } = useContext(Context.UserContext);
    const { config } = useContext(Context.ConfigContext);
    const { grid, isHorizontal } = useContext(Context.GridContext);
    const { currentActivity, onChangeActivity, currentAssessment, resetCurrentActivity } = useContext(Context.EvaluationsContext)
    
    const [disabledButton, setDisabledButton] = useState(true);
    const [sendMessagesEnabled, setSendMessagesEnabled] = useState(false);
    const [timeoutActivity, setTimeoutActivity] = useState(false);

    const forceEndActivity = useRef(null);
    const sendClickButton = useRef(null);
    
    window.forceEndActivity = forceEndActivity.current
    window.currentActivity = currentActivity

    //Timers
    const [timerAssessment, startTimerAssessment, restartTimerAssessment] = useTimer(config.MAX_TIME_AUTOEVALUATION);
    const [timerActicity, startTimerActicity, restartTimerActicity] = useTimer(config.MAX_TIME_ACTIVITY);

    // user info handler
    const [userInfo, setUserInfo] = useState(false)
    const [userInfoSended, setUserInfoSend] = useState(false)

    const hideForm = useMemo(()=> {
        if (!showFormInfo) {
            return true;
        }
        if (userInfoSended) {
            return true;
        }
        return false;
    },[showFormInfo, userInfoSended])

    const layout = useGridLayout(hideForm ? opinionTest : opinionTestWithoutUser)
    /**
     * layout can be:
     * opinionTest -> [title, rightBox, image, evaluation]
     * opinionTestWithoutUser -> [title, image, form]
    */
   const withUserInfo = layout.length > 3 
   
   useEffect(()=> {
        window.scrollTo(0,0)
        let user_info = localStorage.getItem('user_info')
        if (user_info) {
            setUserInfo(JSON.parse(user_info))
            setUserInfoSend(true)
        }
    }, [])

    useEffect(()=> {
        if (hideForm) {
            startTimerAssessment();
        }

        if(!WITHOUT_TIME && timerAssessment <= 0){
            //End Assessments
            navigate('/opinion-test/result');
        }

        if (!WITHOUT_TIME && timerActicity <= 10) {
            setTimeoutActivity(true);
        }

        if(!WITHOUT_TIME && timerActicity <= 0){
            //Send nextActivity
            setSendMessagesEnabled(false);
            forceEndActivity.current(currentActivity);
            return
        }

    }, [
        timerAssessment, 
        timerActicity, 
        userInfo, 
        currentActivity, 
        navigate, 
        startTimerAssessment, 
        hideForm
    ])

    const onStartActivity = ()=> {
        setTimeoutActivity(false);
        restartTimerActicity();
        startTimerActicity();
        onChangeActivity();
    }
   
    const onEndAssessment = ()=> {
        resetCurrentActivity();
        navigate('/opinion-test/result')
    }

    const onEndActivity= ()=> {
        setDisabledButton(true)
    }

    const onActivityAnswered = ()=> {
        setDisabledButton(false)
    }


    if(!evaluation?.evaluation && evaluation.evaluation.length > 0) return null;
    if(evaluation?.evaluation[0].name !== 'Autoevaluación') return <Navigate replace to="/teacher-test" />
    

    const handleSubmit = async (evt) => {
        evt.preventDefault()
        await saveUserInfo(userInfo, user.lti)
        setUserInfoSend(true)
    }

    const handleUser = (evt, type) => {
        let newUser = userInfo ? {...userInfo} : {}
        newUser[type] = evt.target.value 
        setUserInfo(newUser)
    }

    if (!layout.length > 0) return null;
    
    return (
        <div 
            className="container-image-teacher" 
        >
            <div style={{
                backgroundImage: `url('/images/women_1.png')`,
                backgroundRepeat:'no-repeat',
                backgroundSize: 'contain',
                ...layout[withUserInfo ? 2 : 1]
            }}></div>
            <div className='teacher-container-activity'>
                <h1
                    style={{
                        top: layout[0].top,
                        left: layout[0].left,
                        lineHeight: `${grid.factor *2}px`,
                        paddingTop: grid.factor *2
                    }}
                    className='title-mhe-red'
                >{!hideForm ?  'Datos estadísticos' : 'Autoevaluación'}</h1>
                
                <div
                    className="teacher-iframe-activity border-double-mix"
                    style={{
                        ...layout[withUserInfo ? 3 : 2],
                        backgroundColor: 'white'
                    }}
                >{ hideForm
                    ? (
                        <XIfame 
                            style={{
                                position: 'relative',
                                width: '100%',  
                                maxHeight: '100%' 
                            }}
                            userName={user.email}
                            assessmentId={evaluation.evaluation[0].subjects.id}
                            title='Opinion scale iframe'
                            forceEndActivityFunc={forceEndActivity}
                            nexActivityFunc={sendClickButton}
                            eventsIframe={{
                                "answered-activity": onActivityAnswered,
                                'end-activity': onEndActivity,
                                'end-assessment': onEndAssessment,
                                'start-activity': onStartActivity
                            }}
                        />
                    ) : (
                        <InfoUserForm
                            width={layout[2].width - grid.factor*1} 
                            height={layout[2].height - grid.factor*2} 
                            handleSubmit={handleSubmit} 
                            handleUser={handleUser}
                        />
                    )
                }
                </div>
            </div>
            {hideForm && (
                    <RightBox
                        container={layout[1]}
                        currentActivity={currentActivity} 
                        currentTimer={timerActicity} 
                        disabledButton={disabledButton} 
                        globalTimer={timerAssessment} 
                        onClick={sendClickButton.current}
                        totalActivities={22}
                    />
                )
            }
        </div>
    )
}

export default FirstActivity
