import React, { useContext, useEffect, useMemo } from 'react'
import Xbutton from '../../components/XButton/Xbutton'
import { useNavigate } from "react-router-dom";

import Context from '../../context';
import { getLocalUserToken } from '../../helpers/auth.helpers';

//Scss
import './Home.scss'
import XContainerScroll from '../../components/XContanerScroll/XContainerScroll';
import useGridLayout from '../../hooks/useGridLayout';
import { home } from '../../layouts';


const Home = () => {
    
    let navigate = useNavigate();
    const {getUserEvaluation, user } = useContext(Context.UserContext)
    const {getConfig, config} = useContext(Context.ConfigContext)
    const {setLoading} = useContext(Context.LoadingContext)

    const [main, video, button1, button2] = useGridLayout(home, 'right')

    useEffect(() => {
        const init = async()=> {
            getLocalUserToken();
            try {
                await getConfig();
            } catch (error) {

            }
        }
        init()
    }, [])

    const onClickButtonInitTest = async (redirect, newEvaluation) => {
        try {
            const lti = getLocalUserToken();
            setLoading(true)
            if (newEvaluation) {
                await getUserEvaluation(lti);
            }
            setLoading(false)
            navigate(redirect)
        } catch (error) {
            setLoading(false)
            console.log("error")
        }
    }

    if (!main) return null;
    
    return (
        <div className='home'> 
            {/* HOME MAIN */}  
            <XContainerScroll 
                className="home-text bg-white border-double-mix"
                position={{top: main.top, left: main.left}}
                {...{width: main.width, height: main.height}}
                styles={{display: main.display}}
            >

                <h2 className="homeTitle title-mhe-red" style={{background: 'none'}}>Prueba diagnóstico sobre la Competencia Digital Docente</h2>
                <h3 className="homeSubtitle subtitle-mhe-blue">McGraw Hill Education</h3>

                <div className="home-explain">
                    <p>Para iniciar la prueba pulse "Iniciar Evaluación". Comenzarás realizando un test autodiagnóstico para conocer tu nivel de Competencia Digital Docente. Antes de empezar, te aconsejamos ver un ejemplo de navegación y funcionamiento de la herramienta en el vídeo situado en la parte derecha de la pantalla.</p>
                    <p>Si lo deseas, cuando termines podrás ver tus estadísticas pulsando el botón "Mi Reporte". La prueba tendrá una duración de unos 60 minutos. Comienza a realizarla cuando estés preparado.</p>
                    <p>¡Empezamos!</p>
                </div>
                
                <div>
                    <div className="col-8 mt-3">
                        <a className="link" href="#">Descarga la guía de usuario</a>
                    </div>
                    <div className="col-8 mt-3">
                        <a className="link" href="#">¿Necesitas ayuda? Soporte para el uso de la herramienta</a>
                    </div>
                </div>
            </XContainerScroll>

            {/* HOME VIDEO */}
            <div className="home-video" style={{
                backgroundColor: 'white', //'#06235b',
                width: video.width, height: video.height,
                top: video.top, left: video.left,
                display: video.display
            }}>
                <iframe 
                    id="videos"                            
                    src="https://player.vimeo.com/video/652026006?h=a21014e484&badge=0&autopause=0&player_id=0&app_id=58479" 
                    title="Vimeo video player" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                ></iframe>
            </div>
            
            {/* HOME BUTTONS */}
            <div className='home-buttons'>
                <div style={{zIndex: 2 }}>
                    <Xbutton
                        style={{
                            position: 'absolute',
                            width: button1.width, height: button1.height,
                            top: button1.top, left: button1.left, 
                            display: button1.display
                        }} 
                        className="home-button x-button"
                        buttonDisabled={Object.keys(user.evaluations).length >= config.MAX_TRYS}
                        long={true} 
                        onClick={ ()=> onClickButtonInitTest('/opinion-test', true) }
                    >Iniciar Evaluación</Xbutton> 
                </div>
                <div style={{zIndex: 2}}>
                    <Xbutton 
                        style={{
                            position: 'absolute',
                            width: button2.width, height: button2.height,
                            top: button2.top, left: button2.left, 
                            display: button2.display
                        }}
                        className="home-button x-button"
                        buttonDisabled={Object.keys(user.evaluations).length === 0}
                        long={true} 
                        onClick={()=>  onClickButtonInitTest('/final-page', false) }
                    >Mi Reporte</Xbutton> 
                </div>
            </div>
        </div>
    )
}

export default Home
