export const secontToClock = (seconds)=>{
    let mins = Math.floor(seconds / 60);
    let restSeconds = seconds%60;

    mins = mins < 10 ? '0'+ mins : mins;
    restSeconds = restSeconds < 10 ? '0'+ restSeconds : restSeconds;
    
    return `${mins}:${restSeconds}`;
}

export const  generateRandomString = (num) => {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result1= '';
    const charactersLength = characters.length;
    for ( let i = 0; i < num; i++ ) {
        result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result1;
}

export const getDistanceFrom = (side, grids, withBorder) => {
    let size = window.grid.size * grids
    
    if (side === 'right' || side === 'top') {
        size += window.grid.factor
    }

    if (withBorder && (side === 'right' || side === 'top')) size -= window.grid.factor

    return {[side]: size}
}

export const getSizeFromGrid = (height, width, withBorder = false) => {
    let output = {}
    if (height) {
        output.height = (window.grid.size * height) + (withBorder ? window.grid.factor  : 0)
    }
    if (width){
        output.width = (window.grid.size * width) + (withBorder ? window.grid.factor  : 0)
    }

    return output
}

