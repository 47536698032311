const result = [
    // Container
    {
        fullWidth: true,
        centerX: true,
        centerY: true,
        is_graph: true
    }
]

export default result