import React from 'react'
import './Xbutton.scss'

const Xbutton = ({children, className, id, onClick, long=false, buttonDisabled, style }) => {
    

    return (
        <>
            <button 
                style={style}
                disabled={buttonDisabled}
                className={ "btn sample btn-sample btn-ximdex " + (long ? 'btn-lg ' : '')  + ( className ? className : '') }
                id={id}
                onClick={onClick}
            >
                { children }
            </button>
        </>
    )
}

export default Xbutton
