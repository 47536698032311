import React, { useEffect, useContext, useState} from 'react'
import { useNavigate } from 'react-router-dom';

import Context from '../../context'
import { removeLocalUserToken } from '../../helpers/auth.helpers';
import useQuery from '../../hooks/useQuery';

const Login = () => {
    let navigate = useNavigate();
    const { lti, report, hideform } = useQuery(["lti", "report", "hideform"]);
    const { loginUser, user, getUserWhoami, handleShowFormInfo} = useContext(Context.UserContext);
    const [loadUser, setLoadUser] = useState(false)

    useEffect(()=> {
        handleShowFormInfo(!hideform)
    }, [hideform, handleShowFormInfo])
    
    useEffect(()=> {
        if(!user && !loadUser){
            const init = async ()=> {
                try {
                    const data = await loginUser(lti);
                    const whoami = await getUserWhoami(lti);
                    if(!data) removeLocalUserToken();
                    if (report && whoami.is_teacher) {
                        navigate('/report')
                    } else {
                        navigate('/');
                    }
                    console.log(data)
                } catch (error) {
                    removeLocalUserToken();
                    navigate('/');
                } finally{
                    setLoadUser(true)
                }
            }
            init()
        }
    }, [user, loginUser, lti, report, navigate])

    return (
        <div>
            
        </div>
    )
}

export default Login
