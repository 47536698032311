import Axios from 'axios';
import { setLocalUserToken, getLocalUserToken } from '../helpers/auth.helpers';
import demoEvaluation from '../mockup';

// const init = {"lti":"enrol_lti33bcb5d21e588b7154e94ef3aa7051e172755350","id":567,"email":"elegaz@ximdex.com","num_max_try":4,"try":1,"evaluation":[{"name":"Autoevaluaci\u00f3n","area":0,"subjects":{"id":"2"}},{"name":"try_1","subjects":[{"name":"Compromiso profesional","area":1,"id":"2629"},{"name":"Contenidos digitales","area":2,"id":"2630"},{"name":"Ense\u00f1anza y aprendizaje","area":3,"id":"2631"},{"name":"Evaluaci\u00f3n","area":4,"id":"2632"},{"name":"Empoderamiento de los estudiantes","area":5,"id":"2633"},{"name":"Competencias de los estudiantes","area":6,"id":"2634"}]}],"evaluations":[{"name":"Autoevaluaci\u00f3n","area":0,"subjects":{"id":"2"}},{"name":"try_1","subjects":[{"name":"Compromiso profesional","area":1,"id":"2629"},{"name":"Contenidos digitales","area":2,"id":"2630"},{"name":"Ense\u00f1anza y aprendizaje","area":3,"id":"2631"},{"name":"Evaluaci\u00f3n","area":4,"id":"2632"},{"name":"Empoderamiento de los estudiantes","area":5,"id":"2633"},{"name":"Competencias de los estudiantes","area":6,"id":"2634"}]}]}
// const intento = {"lti":"enrol_lti33bcb5d21e588b7154e94ef3aa7051e172755350","id":567,"email":"elegaz@ximdex.com","num_max_try":4,"try":2,"evaluation":[{"name":"try_2","subjects":[{"name":"Compromiso profesional","area":1,"id":"2647"},{"name":"Contenidos digitales","area":2,"id":"2648"},{"name":"Ense\u00f1anza y aprendizaje","area":3,"id":"2649"},{"name":"Evaluaci\u00f3n","area":4,"id":"2650"},{"name":"Empoderamiento de los estudiantes","area":5,"id":"2651"},{"name":"Competencias de los estudiantes","area":6,"id":"2652"}]}]}

export const getUser = async (lti)=> {
    setLocalUserToken(lti)
    const develop = process.env.REACT_APP_MODE === 'develop';
    const uri = `${process.env.REACT_APP_URI_API}/user/${lti}?debug=${develop}`
    const { data } = await Axios.get(uri)
    return data;
}

export const getWhoami = async (lti) => {
    const uri = `${process.env.REACT_APP_URI_API}/whoami`;
    const config = { headers: {'Authorization' : lti}}
    const { data } = await Axios.get(uri, config);
    if (data.info) {
        // localStorage.setItem('user_info', JSON.stringify(data.info))
    }
    return data;
}

export const getNewTry = async()=> {    
    const lti = getLocalUserToken()
    const uri = `${process.env.REACT_APP_URI_API}/user/${lti}/evaluation`
    const { data } = await Axios.get(uri)
    return data;
}

export const getServerConfig = async () => {
    const uri = `${process.env.REACT_APP_URI_API}/config`
    const { data } = await Axios.get(uri)
    return data;
}

export const getQualificationInitial = async(lti, init = false) =>{
    const develop = process.env.REACT_APP_MODE === 'develop';
    const uri = `${process.env.REACT_APP_URI_API}/qualifications/${lti}?debug=${develop}${init ? '&init=true' : ''}`

    if (develop) return demoEvaluation
    const { data } = await Axios.get(uri)
    return data;
}

export const saveUserInfo = async (payload, lti) => {
    if (!payload?.userInfo) {
        payload  = {
            info: payload
        }
    }

    const develop = process.env.REACT_APP_MODE === 'develop';
    const uri = `${process.env.REACT_APP_URI_API}/user/${lti}?debug=${develop}`
    const { data } = await Axios.post(uri, payload)
    return data;
}