import React, { useContext } from 'react'
import { ELEMENTS_VIEWPORT, POSITION } from '../../constants'
import Context from '../../context'
import { getDistanceFrom } from '../../helpers/tools.helpers'
import Logo from '../Logo/Logo'
import './WrappedGrid.scss'

function WrapperGrid({children, logo}) {
  const { grid, elements } = useContext(Context.GridContext)
  return (
    <div className='wrapped-grid'>
      <div style={{
        position: 'absolute',
        top: 0,
        left: elements.x > ELEMENTS_VIEWPORT.x ? getDistanceFrom(POSITION.LEFT, Math.ceil((elements.x - ELEMENTS_VIEWPORT.x) / 2 ), true).left : 0,
        width: grid.size * ((elements.x - ELEMENTS_VIEWPORT.x) % 1 === 0 ? ELEMENTS_VIEWPORT.x : ELEMENTS_VIEWPORT.x - 1) + grid.factor,
        height: '100%',
        minHeight: grid.size * ((elements.y - ELEMENTS_VIEWPORT.y) % 1 === 0 ? ELEMENTS_VIEWPORT.y : ELEMENTS_VIEWPORT.y - 1) + grid.factor,
        // background: process.env.REACT_APP_MODE === 'develop' ? '#ff000033' : 'none',
      }}>
        {children}
      </div>
        {logo && <Logo side={logo} />}    
    </div>
  )
}

export default WrapperGrid