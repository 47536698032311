import React, { useState } from 'react';
import LoadingContext from './LoadingContext';

import "./LoadingStyles.css"

const LoadingState = (props)=> {
    const [loading, setLoading] = useState(false)

    return (
        <LoadingContext.Provider value={
            {
                loading,
                setLoading
            }
        }>
            {
                props.children
            }
            {loading &&
                <div id="container-loading">
                    <div className="loader"></div>
                </div>
            }
        </LoadingContext.Provider>
    )
    
}

export default LoadingState;