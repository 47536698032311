import React from 'react'

import './XBar.scss'

const XBar = ({max, current}) => {
    const accomplishedBars = []
    let currentBars = current
    while(currentBars > 0){
        currentBars--;
        accomplishedBars.push(<div className="accomplished"></div>)
    }
    const normalBars = []
    let numberNormal = max - current;

    while(numberNormal > 0){
        numberNormal--;
        normalBars.push(<div className="actual"></div>)
    }
    return (
        <div className="progressBar">
            <div id='progressDiv'>
                {accomplishedBars}
                {normalBars}
            </div>
            <label className='label'>
                {current} / {max}
            </label>
        </div>
    )
}

export default XBar
