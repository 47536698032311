import Axios from "axios";
const TOKEN_KEY = '@evaluation_teacher:token';


export const getLocalUserToken = ()=> {
    return localStorage.getItem(TOKEN_KEY)
}

export const setLocalUserToken = (lti)=> {
    localStorage.setItem(TOKEN_KEY, lti)
}

export const removeLocalUserToken = ()=> {
    return localStorage.removeItem(TOKEN_KEY)
}

export const initAxiosInterceptors = ()=> {
    Axios.interceptors.request.use(config=> {
        let token;
        try {
            token = getLocalUserToken();
        } catch (error) {}

        if(token) {
            config.headers.Authorization = token;
            config.headers["Content-Type"] = "application/json";
        }
        return config;
    })
}