import React, { useState, useRef, useEffect } from 'react'
const XIfame = ({
        assessmentId, userName, 
        title, forceEndActivityFunc,
        className, nexActivityFunc, style,
        eventsIframe, onEndAssessment
    }) => {

    const [ height, setHeight ] = useState(600);
    const [eventIteration, setEventIteration] = useState(0)
    const activityIframe = useRef(null)

    const urlIframe = process.env.REACT_APP_XEVAL + `/moodle-evaluation?assessment_id=${assessmentId}&target=mheDashboard&isApp=true&student_id=${userName}`
    
    const forceEndAcivity = (currentActivity)=> {
        activityIframe.current.contentWindow.postMessage({message: 'force-end-activity', currentActivity: currentActivity}, "*")
    }

    const nexActivity = ()=> {
        activityIframe.current.contentWindow.postMessage({message: 'nex-activity'}, "*")
    }
    
    const update = ({data})=> {
        if(data.height){
            setHeight(data.height+40);
            return
        }

        if(data.iteration && data.iteration !== eventIteration) {
            setEventIteration(data.iteration)

            Object.keys(eventsIframe).forEach(key=>{
                if(data.event === key && typeof eventsIframe[key] === 'function'){
                    eventsIframe[key](data.data)
                }
            })
        }
    }

    useEffect(()=> {
        
        if(forceEndActivityFunc && forceEndActivityFunc.current === null && forceEndAcivity){
            forceEndActivityFunc.current = forceEndAcivity;
        }
        if(nexActivityFunc && nexActivityFunc.current === null &&  nexActivity){
            nexActivityFunc.current = nexActivity;
        }
        
        window.addEventListener('message', update);

        return ()=> {
            window.removeEventListener('message', update)
        }
    }, [forceEndActivityFunc, forceEndAcivity, nexActivityFunc, nexActivity, update])

    return (
        <>
            <iframe
                style={style}
                id='xeval'
                ref={activityIframe}
                className={className}
                height={height}
                title={title}
                src={urlIframe} 
            ></iframe>
        </>
    )
}

export default XIfame
