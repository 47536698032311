const demoEvaluation = {
  grade_system: {
    "*": {
      name: "0",
      color: "#aaa",
      value: 0,
      parse_value_percent: 0,
    },
    "<A1": {
      name: "<A1",
      color: "#BF0411",
      value: 1,
      parse_value_percent: 12.5,
    },
    A1: {
      name: "A1",
      color: "#CE6B00",
      value: 2,
      parse_value_percent: 25,
    },
    A2: {
      name: "A2",
      color: "#DB9600",
      value: 3,
      parse_value_percent: 37.5,
    },
    B1: {
      name: "B1",
      color: "#F1CE00",
      value: 4,
      parse_value_percent: 50,
    },
    B2: {
      name: "B2",
      color: "#B6C72C",
      value: 5,
      parse_value_percent: 62.5,
    },
    C1: {
      name: "C1",
      color: "#8DAF34",
      value: 6,
      parse_value_percent: 75,
    },
    C2: {
      name: "C2",
      color: "#54963D",
      value: 7,
      parse_value_percent: 87.5,
    },
  },
  evaluations: {
    initial: {
      name: "initial",
      subjects: [
        {
          qualification: {
            qualification: 1,
            grade_system_qualification: "<A1",
            goal_to_improve: [],
          },
          area: 0,
          name: "Autoevaluación",
        },
      ],
      qualification: {
        qualification: {
          qualification: 1,
          grade_system_qualificaton: "<A1",
        }
      },
    },
  },
};

export default demoEvaluation