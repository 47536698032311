import React, { useContext } from 'react'
import Context from '../../context'

function Logo({side}) {

    const {grid} = useContext(Context.GridContext)
    
    const styles = {
        width:  grid.size,
        height:  grid.size,
        position: 'absolute',
        borderRadius: 0 
    }

    if (!side.includes('left')) {
        styles.right = grid.factor + ( grid.size)
    } else {
        styles.left = grid.size
    }

    if (!side.includes('bottom')) {
        styles.top =  grid.factor + ( grid.size)
    } else {
        styles.bottom = grid.size
    }
  return (
    <img style={styles}
        src='/images/mhe.png'
        alt="MHE Education logo"
    ></img>
  )
}

export default Logo