const useQuery = (getParams)=> {
    let urlQuery = new URLSearchParams(window.location.search)
    let querys = {};
    if(getParams){
        getParams.forEach(element => {
            querys[element] = urlQuery.get(element);
        });
    }
    
    return querys;
}

export default useQuery;