import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Xbutton from '../../components/XButton/Xbutton';
import Context from '../../context';
import { getLocalUserToken } from '../../helpers/auth.helpers';
import useGridLayout from '../../hooks/useGridLayout';
import finalTest from '../../layouts/finalTest.layout';

const dashboard_uri = process.env.REACT_APP_DASHBOARD_URI;
const dashboard_evaluation_uri = process.env.REACT_APP_DASHBOARD_EVALUATION_URI;
const {REACT_APP_SCORM_URI_WITH_FORM, REACT_APP_SCORM_URI_WITHOUT_FORM} = process.env

const FinalPage = () => {
    const { user, showFormInfo, getUserEvaluation } = useContext(Context.UserContext);
    const [container] = useGridLayout(finalTest, 'left')
    const {setLoading} = useContext(Context.LoadingContext)
    const navigate = useNavigate()
    const backUrl = showFormInfo ? REACT_APP_SCORM_URI_WITH_FORM : REACT_APP_SCORM_URI_WITHOUT_FORM;
    
    const handleGoToDashboard = () => {
        const url = `${dashboard_uri}?userId=${user.lti}&toUrl=${dashboard_evaluation_uri}&backUrl=${backUrl}`;

        return window.open(url, 'blank')
    }
    
    const handleNextEval = async () => {
        try {
            const lti = getLocalUserToken();
            setLoading(true)
            await getUserEvaluation(lti, false);
            setLoading(false)
            navigate('/teacher-test')
        } catch (error) {
            setLoading(false)
            console.log("error")
        }
    }

    return (
        <div className='bg-white border-double-mix' style={{
            ...container,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'space-evenly',
            gap: 25,
            alignItems: 'center',
        }}>
            <span style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <h1 className='title-mhe-blue' style={{fontSize: 'calc(var(--font-size-by-grid) * 2)'}}>Aquí puedes ver tu reporte</h1>
                <Xbutton
                    id='buttonGoDashboard' 
                    className='home-button bgmhe-red x-button'
                    onClick={handleGoToDashboard} 
                    style={{ border: 'none' }}
                >Ver reporte</Xbutton>
            </span>
            {user.try < user.num_max_try && (
                <span  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <h1 className='title-mhe-blue' style={{fontSize: 'calc(var(--font-size-by-grid) * 2)'}}>Aquí puedes hacer otro nuevo intento</h1>
                    <Xbutton
                        className='home-button bgmhe-red x-button'
                        onClick={handleNextEval} 
                        style={{ border: 'none' }}
                        id='buttonGoDashboard' 
                    >Nuevo intento</Xbutton>
                </span>
            )}
        </div>
    )
}

export default FinalPage
