const opinionTest = [
  // Title Area
  {
    height: 1,
    width: 1,
    top: 0,
    left: 3,
    fullWidth: true,
  },
  // Right Box - timer
  {
    width: 3,
    height: 3,
    top: 2,
    left: 19,
    border: true,
    fullWidth: true,
    is_timer: true
  },
  // Image
  {
    height: 6,
    width: 3.5,
    top: 3,
    left: 0,
    image_sidebar: true,
    isUserInfo: true,
    fullWidth: true,
  },
  // Evaluation
  {
    height: 8,
    width: 15,
    top: 2,
    left: 3,
    fullWidth: true,
    border: true,
    minHeight: 12
  },
];

export const opinionTestWithoutUser = [
  // Title Area
  {
    height: 1,
    width: 1,
    top: 0,
    left: 3,
    fullWidth: true,
    isUserInfo: true,
  },
  // Image
  {
    height: 6,
    width: 3.5,
    top: 3,
    left: 0,
    image_sidebar: true,
    isUserInfo: true,
    fullWidth: true,
  },
  // form user
  {
    // height: 8,
    width: 18,
    top: 2,
    left: 4,
    minHeight: 20,
    fullWidth: true,
    fullHeight: true,
    border: true,
  },
];

export default opinionTest;
