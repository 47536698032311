import Login from './../pages/Login/Login'
import { Route, Routes, Navigate } from 'react-router-dom'
import { getLocalUserToken } from '../helpers/auth.helpers'

import {generateRandomString, getDistanceFrom, getSizeFromGrid} from './../helpers/tools.helpers'
import './LogoutRoutes.scss'
import { POSITION } from '../constants'
import WrapperGrid from '../components/WrapperGrid/WrappedGrid'
import useGridLayout from '../hooks/useGridLayout'
import { logout } from '../layouts'


const LogoutRoutes = () => {

    return (
        <Routes>
            <Route path="/login" element={<WrapperGrid logo='right'><Login /></WrapperGrid>}/>
            <Route path="*" element={<WrapperGrid logo='right'><OutLogin /></WrapperGrid>}/>
        </Routes>
    )
}

const OutLogin = ()=> {
    const [container] = useGridLayout(logout, 'right')

    const tokenUser = process.env.REACT_APP_MODE === 'develop' 
        ? generateRandomString(10)
        : getLocalUserToken();
    
    if(tokenUser) return <Navigate replace to={`/login?lti=${tokenUser}`} />

    return (
        <div className='logout-wrapper border-double-red' style={{...container}}>
            <div className='logout-info-box' >
                <h2 className='title-mhe-blue'>Error:</h2>
                <div>
                    <p style={{
                        fontSize: 'min(calc(var(--font-size-by-grid) * 1.6), 1.2rem)'
                    }}>No tienes ninguna credencial que te identifique. <a className="link" href="#">¿Necesitas ayuda? Soporte para el uso de la herramienta</a></p>
                </div>
            </div>
        </div>
    )
}


export default LogoutRoutes
