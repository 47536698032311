
const report = [
    // Main Area
    {
      height: 7,
      width: 11,
      top: 1,
      left: 12,
      fullWidth: true,
    },
    // Image
    {
      height: 7,
      width: 9,
      top: 2,
      left: 2,
      fullWidth: true,
    },
    // button
    {
      height: 1,
      width: 5,
      top: 9,
      left: 15,
      fullWidth: true,
    }
  ];
  
  export default report;
  