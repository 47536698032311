import React, { useState } from 'react';
import ErrorContext from './ErrorContext';

//Import Styles
import "./ErrorStyles.scss";

const UserState = (props)=> {
    const [error, setErrorState] = useState(null);

    const setError = (newError)=> {
        setErrorState({
            title: newError.title,
            content: newError.content
        })
    }

    const deleteError = ()=> {
        setErrorState(null);
    }


    
    return (
        <ErrorContext.Provider value={{
            setError,
            deleteError
        }}>
            { props.children }
            { error &&
                <div className="modalContainer">
                    <div className="modal-content">
                        <span 
                            className="close"
                            onClick={deleteError}
                        >×</span>
                        <h2>
                            { error.title }
                        </h2>
                        <p>
                            { error.content }
                        </p> 
                    </div>
                </div>
            }
            
        </ErrorContext.Provider>
    )
}

export default UserState;