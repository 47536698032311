import { useEffect, useMemo, useState } from "react";
import { ELEMENTS_VIEWPORT, VIEWPORT } from "../../constants";
import GridContext from "./GridContext";


const GRID_INITIAL_SIZE = 140;
const GRID_FACTOR = { img_size: 1, size: 0.5, factor: 17.5 / 140}


export default function GridState({children}) {

    const [grid_size, setGridSize] = useState(GRID_INITIAL_SIZE)
    const [dimensions, setDimensions] = useState({width: '100%', height: '100%'})
    const [elements, setElements] = useState({x: 0, y: 0})
    
    const isHorizontal = useMemo(() => {
        console.log(dimensions.height, window.innerHeight)
        if ( dimensions.height >  window.innerHeight) {
            document.querySelector('body').style.overflowY = 'auto'
        }
        if (dimensions.width >= VIEWPORT && dimensions.height < window.innerHeight) {
            document.querySelector('body').style.overflowY = 'hidden'
            return true
        }
        document.querySelector('body').style.overflowY = 'auto'
        return false
    }, [dimensions])
    
    const grid = useMemo(()=> {
        let newGrid =  {
            img_size: grid_size * GRID_FACTOR.img_size, 
            size: grid_size  * GRID_FACTOR.size, 
            factor: grid_size  * GRID_FACTOR.factor,
        }
        let newFontByGrid = (1.2 * newGrid.img_size) / GRID_INITIAL_SIZE
        if (newFontByGrid < 0.5) newFontByGrid = 0.5
        document.documentElement.style.setProperty('--font-size-by-grid', `${newFontByGrid}rem`)
        document.documentElement.style.setProperty('--grid-portion', `${newGrid.factor}px`)

        window.grid = newGrid

        return newGrid
    }, [grid_size, isHorizontal])

    const debouncedHandleResize = debounce(function handleResize() {
        let {innerWidth} = window

        if (innerWidth < 670) {
            setGridSize(Math.floor(innerWidth / ELEMENTS_VIEWPORT.minX ) * 2)
        } else {
            let width = innerWidth - (innerWidth % window.grid.img_size) - ( window.grid.size * 2 )
            
            let current = width / grid.size
            let newSize = Math.floor(width / 12)
            
            if (newSize > 140) newSize = 140
            
            if (current < 24 || (grid_size < newSize )) {
                setGridSize(newSize)
            }
        }
    }, 1000)

    
    useEffect(() => {
        debouncedHandleResize()
        setTimeout(debouncedHandleResize, 1001)
    }, [])

    
    useEffect(() => {
        window.addEventListener('resize', debouncedHandleResize)
        return () => window.removeEventListener('resize', debouncedHandleResize)
    })

    useEffect(() => {
        if (!isNaN(dimensions.width) && !isNaN(dimensions.height)) {
            setElements({
                x: Math.floor(dimensions.width / grid.size),
                y: Math.floor(dimensions.height / grid.size)
            })
        }
    }, [dimensions])

    useEffect(() => {
        let {innerHeight, innerWidth} = window
        let width = innerWidth - (innerWidth % grid.img_size) - ( grid.size * 1 )

        let height = innerHeight - (innerHeight % grid.img_size) + grid.factor
        if (innerHeight % grid.img_size < grid.size) {
            height -= grid.size
        }

        // min height of 10 grids elements
        let elementsX = Math.floor(height / grid.size)
        if (elementsX <= 10) {
            height = height + (grid.size * (11 - elementsX )) 
        }

        console.log({height})
        
        setDimensions({
            height: height,
            width: width
        })
    }, [grid_size, grid])

    const handleTotalHeight = (grids) => {
        setDimensions({
            height: (grids * grid.size),// + grid.size + grid.factor,
            width: dimensions.width
        })
    }

    return (
        <GridContext.Provider
            value={{
                dimensions,
                grid,
                elements,
                isHorizontal,
                handleTotalHeight
            }}
        >{children}
        </GridContext.Provider>
    )
}

function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }