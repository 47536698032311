import React, { useEffect, useState, useContext } from 'react'
import ChartOpinionScale from '../../../components/Charts/ChartOpinionScale'
import { useNavigate } from 'react-router-dom';
import Context from '../../../context';
import  { getLocalUserToken } from '../../../helpers/auth.helpers'
import { getQualificationInitial } from '../../../api/api'
import useGridLayout from '../../../hooks/useGridLayout';
import { result } from '../../../layouts';
import Xbutton from '../../../components/XButton/Xbutton';

const Result = () => {
    const navigate = useNavigate()
    const {getUserEvaluation, user } = useContext(Context.UserContext);
    const {setLoading} = useContext(Context.LoadingContext)

    const [qualification, setQualification] = useState(null);
    const [container] = useGridLayout(result, 'right')

    useEffect(()=> {

        const getQualifications = async (lti) => {
            try {
                const qualificationResponse = await getQualificationInitial(lti, true);
                setQualification(qualificationResponse);
            } catch (error) {
                console.log(error);
                throw new Error(error);
            }
        }

        getQualifications(user.lti);
        
    }, []);

    const handleNextEval = async () => {
        try {
            const lti = getLocalUserToken();
            setLoading(true)
            await getUserEvaluation(lti, true);
            setLoading(false)
            navigate('/teacher-test')
        } catch (error) {
            setLoading(false)
            console.log("error")
        }
    }
    

    if (qualification === null || ( qualification.evaluations !== null && Object.keys(qualification.evaluations).length === 0)) return null;
    else {
        return (
            <div className='result-wrapper bg-white' 
                style={{
                    position: 'absolute',
                    padding: '15px',
                    ...container,
                }}
            > 
                <h2 
                    className='subtitle-mhe-blue'
                    style={{
                        fontSize: 'calc(var(--font-size-by-grid) * 1.8)'
                    }}
                >Según tu percepción docente estarías ubicado en el siguiente nivel de competencia digital docente.</h2>
                <div 
                    className="result-chart"
                    style={{
                        width: container.width - window.grid.factor,
                        height: container.height - (window.grid.img_size * (window.innerWidth > 1280 ? 2 : 3))
                    }}
                >
                    <ChartOpinionScale 
                        className="chart-options" 
                        note={qualification.evaluations.initial.qualification.grade_system_qualificaton ??qualification.evaluations.initial.qualification.qualification.grade_system_qualificaton }
                        width={container.width} 
                        height={container.height - (window.grid.img_size * (window.innerWidth > 1280 ? 2 : 1))}
                    />
                </div>
                <p>Ahora que te has autoevaluado, es el momento de conocer tu nivel real de competencia digital docente.</p>
                <Xbutton 
                    id='buttonCheckResult' 
                    onClick={handleNextEval} 
                    style={{width: '100%'}}
                >Empezar la evaluación completa</Xbutton>
            </div>
        )
    }
}

export default Result
