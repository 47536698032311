import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import Context from './context';
//import { initAxiosInterceptors } from './helpers/auth.helpers';

//initAxiosInterceptors();
ReactDOM.render(
  <React.StrictMode>
    <Context.AplicationState>
      <App />
    </Context.AplicationState>
  </React.StrictMode>,
  document.getElementById('root')
);