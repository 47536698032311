import React, {  useState, useEffect } from 'react'
import { getNewTry, getUser, getWhoami } from '../../api/api';
import UserContext from './UserContext'

const UserState = ({children}) => {
    let [user, setUser] = useState(null);
    let [userWhoami, setUserWhoami] = useState(null);
    let [evaluation, setEvaluation] = useState(null);
    const [showFormInfo, setShowFormInfo] = useState(true);

    useEffect(()=> {

    }, [])

    const loginUser = async (lti)=> {
        try {
            const resUser =  await getUser(lti);
            setUser(resUser)
            return resUser;
        } catch (error) {
            setUser(undefined)
        }
    }

    const getUserWhoami = async (lti) => {
        try {
            const whoami = await getWhoami(lti);
            setUserWhoami(whoami);
            return whoami;
        } catch (error) {
            setUserWhoami(undefined);
        }
    }

    const getUserEvaluation = async (lti, init = false)=> {
        try {
            if (init && evaluation.length > 0) {
                setEvaluation([evaluation[1]])
                return [evaluation[1]]
            } else {
                const resUser =  await getNewTry(lti);
                setEvaluation(resUser)
                return resUser;
            }
        } catch (error) {
            setEvaluation(undefined)
            throw new Error(error)
        }
    }

    const logOut = ()=> {

    }

    const handleShowFormInfo = (show) => {
        setShowFormInfo(show)
    }

    return (
        <UserContext.Provider 
            value={{
                user,
                loginUser,
                userWhoami,
                getUserWhoami,
                logOut,
                getUserEvaluation,
                evaluation,
                handleShowFormInfo,
                showFormInfo
            }}
        >
            { children }
        </UserContext.Provider>
    )
}

export default UserState
