import React, {  useState } from 'react'
import { getServerConfig } from '../../api/api'
import ConfigContext from './ConfigContext'

const ConfigState = ({children}) => {
    const [config, setConfig] = useState({})

    const getConfig = async () => {
        try {
            const data = await getServerConfig();
            setConfig(data)
            return data;
        } catch (error) {
            console.error(error);
            throw new Error(error)
        }
    }

    return (
        <ConfigContext.Provider
                value={{
                    config,
                    getConfig
                }}
            >
                { children }
        </ConfigContext.Provider>
    )
}

export default ConfigState;