import { useContext } from "react";
import Context from "../../context";
import Xbutton from "../XButton/Xbutton";

const InfoUserForm = ({width, height, handleUser, handleSubmit}) => {

    const { grid } = useContext(Context.GridContext);

    return (
        <div style={{ 
            padding: 25, 
            zIndex:2,  
            position: 'relative', 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflowY: 'auto', 
            height,
            width
        }}>
            <div>
                <h3 className="homeSubtitle subtitle-mhe-blue">Los siguientes datos son solicitados para datos estadísticos</h3>
                <form onSubmit={handleSubmit} >
                    <div className="form-group">
                        <label for="centerInput"></label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="centerInput"  
                            placeholder="Centro Docente" 
                            onChange={(evt) => handleUser(evt, 'centro' )}
                        />
                    </div>
                    <div className="form-group">
                        <label for="dptoInput"></label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="dptoInput"  
                            placeholder="Departamento" 
                            onChange={(evt) => handleUser(evt, 'departamento' )}
                        />
                    </div>                           
                    <div className="form-group">
                        <label for="especialidadInput"></label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="especialidadInput"  
                            placeholder="Especialidad docente" 
                            onChange={(evt) => handleUser(evt, 'especialidad' )}
                        />
                    </div>
                    
                    <div className="form-group">
                        <label for="regionInput"></label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="regionInput"  
                            placeholder="Región o localidad" 
                            onChange={(evt) => handleUser(evt, 'region' )}
                        />
                    </div>
                    
                    <div className="form-group">
                        <label for="edadInput"></label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="edadInput"  
                            placeholder="Edad" 
                            onChange={(evt) => handleUser(evt, 'edad' )}
                        />
                    </div>
                    <Xbutton
                        type="submit" 
                        className="btn btn-primary" 
                        style={{
                            margin: `${grid.factor * 2}px 0`,
                            float: 'right'
                        }}
                    >Enviar</Xbutton>
                </form>
            </div>
            <div>
                <p style={{marginTop: 20, clear: 'both'}}>
                    Puede ejercitar los derechos de acceso, rectificación, supresión, oposición, portabilidad y limitación al tratamiento de sus datos 
                    personales mediante solicitud a la dirección de email <a href='mailto:lopd@mheducation.com'>lopd@mheducation.com</a>
                </p>
                <p>Para más información puede visitar nuestra web de <a href='https://www.mheducation.com/privacy/privacy-policy-spanish.html' target="_blank">Protección de Datos</a></p>
            </div>
        </div>
    )
}

export default InfoUserForm