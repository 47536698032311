import { useContext, useMemo } from "react"
import { FaRegClock } from "react-icons/fa"
import Context from "../../context"
import { getSizeFromGrid, secontToClock } from "../../helpers/tools.helpers"
import XBar from "../XBar/XBar"
import Xbutton from "../XButton/Xbutton"
import './RightBox.scss'

const RightBox = ({globalTimer, currentTimer, currentActivity, disabledButton, onClick, totalActivities, container}) => {   
    const size = getSizeFromGrid(3,3, true)
    const {isHorizontal} = useContext(Context.GridContext)

    const position = useMemo(() => {
        let newPos = {
            question: {},
            button: {}
        }
        if (isHorizontal) {
            newPos.question.marginTop = window.grid.size - window.grid.factor
            newPos.button.marginTop = window.grid.size
        } else {
            newPos.question.position = 'absolute'
            newPos.question.left = size.width // + window.grid.size - window.grid.factor
            newPos.question.top = 0
            newPos.question.height = size.height

            newPos.button.position = 'absolute'
            newPos.button.top = window.grid.size * 1 + window.grid.factor
            newPos.button.left = window.grid.size * 7 
        }
        return newPos
    }, [isHorizontal])

    console.log(position)
    return (
        <div className='rightBox' style={container}>

            {/** timer global */}
            <div 
                className="timer timer-eval border-double-red"
                style={{ 
                    ...size, 
                    borderWidth: window.grid.factor,
                    fontSize: `var(--font-size-by-grid)`,
                 }}
            >
                <p className="title"> Tiempo restante <br/> de la prueba </p>
                <p className={"time"  + ( globalTimer <= 120? ' timeout' : '')}>
                    <span><FaRegClock /></span>
                    { secontToClock(globalTimer)}
                </p>
                <XBar max={totalActivities} current={currentActivity} />
            </div>

            {/** timer current question */}
            <div 
                className="timer timer-question border-double-red"
                style={{
                    ...position.question,
                    // marginTop: window.grid.size - window.grid.factor,
                    ...getSizeFromGrid((isHorizontal ? 2 : 3),3, true),
                    fontSize: `var(--font-size-by-grid)`,
                }}
            >
                <p className={"title" + (currentTimer <= 15? ' timeout' : '')}>
                    Tiempo restante <br/> de la pregunta
                </p>
                <p className={"time" + (currentTimer <= 15? ' timeout' : '')}>
                    <span><FaRegClock /> </span>
                    { secontToClock(currentTimer)}
                </p>
            </div>

            {/** Button next question */}
            <div 
                className='timer-button'
                style={{ ...position.button  }}
            >
                <Xbutton 
                    id='init-buttonNextQuestion'
                    className='x-button bgmhe-red'
                    onClick={onClick}
                    disabled={disabledButton}
                    style={{
                        fontSize: 16,
                        ...getSizeFromGrid(1, 3)
                    }}
                >Siguiente pregunta</Xbutton>
            </div>
        </div>
    )
}

export default RightBox
