import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../pages/Home/Home'
import Report from '../pages/Report/Report'
import TeacherTest from '../pages/TeacherTest/TeacherTest'
import OpinionTest from '../pages/OpinionTest/OpinionTest'
import FinalPage from '../pages/FinalPage/FinalPage'
import Result from '../pages/OpinionTest/Result/Result'
import WrapperGrid from '../components/WrapperGrid/WrappedGrid'

const LoginRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/opinion-test" element={(<WrapperGrid logo='left'><OpinionTest /></WrapperGrid>)} />
                <Route path='/opinion-test/result' element={(<WrapperGrid><Result logo='left'></Result></WrapperGrid>)} />
                <Route path="/teacher-test" element={(<WrapperGrid logo='left'><TeacherTest /></WrapperGrid>)} />
                <Route path="/final-page" element={(<WrapperGrid logo='left'><FinalPage /></WrapperGrid>)} />
                <Route path="/report" element={(<WrapperGrid logo='left'><Report /></WrapperGrid>)} />
                <Route path="/" element={(<WrapperGrid logo='right'><Home /></WrapperGrid>)}/>
            </Routes>
        </>
    )
}

export default LoginRoutes
