const notes = {
    "<A1": '#bf0411',
    "A1": '#ce6b00',
    'A2': '#db9600',
    'B1': '#f1ce00',
    'B2': '#b6c72c',
    'C1': '#8daf34',
    'C2': '#54963d'
}

export const generateChartOpiNionScale = (note)=>{
                
    let data =  Array.from(Object.keys(notes), key=> {
        return  key === note.toUpperCase() ? 7 : 1
    })


    return {
        data: {
            labels: Object.keys(notes),
            datasets:[{
                label: 'Dataset 1',
                data: data,
                borderColor: Array.from(Object.keys(notes), key=> notes[key]),
                backgroundColor: Array.from(Object.keys(notes), key=> notes[key]),
            }]
        },
        options: {
            responsive: true,
            scaleLineColor: "rgba(0,0,0,0)",
            maintainAspectRatio: false,
            tooltip: {
                enabled: false
            },
            scales:{
                x: {
                    grid: {
                        display: false,
                        drawBorder: false,
                    }
                },
                y: {
                    max: 10,
                    grid: {
                        display: false,
                        drawBorder: false,
                    }
                }
            },
            layout: {
                padding: {
                  left: -25
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Resultado del test inicial',
                    font: {
                        weight: 'bold',
                        size: '20',
                    },
                    color: '#e21a23',
                    padding: "20"
                },
                chartAreaBorder: {
                    borderColor: 'red',
                    borderWidth: 0,
                    borderDash: [0, 0],
                    borderDashOffset: 0,
                }
            }
        }
    }
} 