import { useEffect, useState } from "react";

const useTimer = (time) => {
    const [timer, setTimer] = useState(time);
    const [isActive, setIsActive] = useState(false)
    
    const start = ()=> {
        setIsActive(true)
    }

    const stop = ()=> {
        setIsActive(false)
    }

    const restart = ()=> {
        setTimer(time)
        setIsActive(false)
    }

    useEffect(()=> {
        let interval = null;
        if (isActive) {
            interval = setInterval(() => {
                if(timer > 0){
                    setTimer( timer - 1);
                }
            }, 1000);
        }else if(!isActive && interval !== null){
            clearInterval(interval)
        }

        return ()=> clearInterval(interval)
    }, [isActive, timer])

    return [
        timer,
        start,
        restart,
        stop,
    ]
}

export default useTimer
