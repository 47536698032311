import React from 'react'
import { Bar } from 'react-chartjs-2'
import { generateChartOpiNionScale } from '../../helpers/charts.helpers'

const ChartOpinionScale = ({id, className, note, height, width}) => {

    console.log({width, height})
    
    const configChart = generateChartOpiNionScale(note);
    return (
        <>
            <Bar 
                {...configChart}
                width={width}
                height={height}
                className={className}
                id={id}
            />
        </>
    )
}

export default ChartOpinionScale
