import { useContext, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";

import XContainer from "./components/XContainer/Xcontainer";
import Context from "./context";

import LoginRoutes from "./routes/LoginRoutes";
import LogoutRoutes from "./routes/LogoutRoutes";

import './App.scss'
import { getDistanceFrom } from "./helpers/tools.helpers";
import { POSITION } from "./constants";


function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}
window.grid_size = 140;
window.grid_factor = { img_size: 1, size: 0.5, factor: 17.5 / 140}
window.grid = { 
  img_size: window.grid_size * window.grid_factor.img_size, 
  size: window.grid_size  * window.grid_factor.size, 
  factor: window.grid_size  * window.grid_factor.factor
}

function App() {
  const { user } = useContext(Context.UserContext);
  const {dimensions, grid, isHorizontal} = useContext(Context.GridContext)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [isHorizontal])

  return (
    <div className="App" 
      style={{ 
        top: (isHorizontal || window.innerHeight > dimensions.height) ? 'inherit' : 0,
        width: dimensions.width + grid.factor,
        height: dimensions.height,
        backgroundImage: `url('/images/grid_100x100_with_spaces.png'), 
          url('/images/grid_100x100_with_spaces.png'),
          url('/images/grid_100x100_with_spaces.png'), 
          url('/images/grid_100x100_with_spaces.png')`,
        backgroundSize: grid.img_size,
        backgroundPosition: `${grid.size}px 0px, 0px 0, 0px ${grid.size}px, 0px ${grid.size}px`,
      }}
    >
      <BrowserRouter>
        { user ? <LoginRoutes /> : <LogoutRoutes /> }
      </BrowserRouter>
    </div>
  );
}

export default App;
