import React from 'react'
import './XContainerScroll.scss'

const XContainerScroll = ({width, height, position, className = '', styles = {}, children}) => {
    
    if (!width && styles.width) width = styles.width
    if (!height && styles.height) height = styles.height

    return (
        <div 
            className={`container-scroll ${className}`}
            style={{width, height, ...position, ...styles}}
        >
            <div className='wrapper-scroll'>
                { children }
            </div>
        </div>
    )
}

export default XContainerScroll
