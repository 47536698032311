//import React from 'react';

//UserStates
import UserState from './User/UserState';
import UserContext from './User/UserContext';

//ErrorStates
import ErrorState from "./Error/ErrorState";
import ErrorContext from './Error/ErrorContext';

//EvaluationStates
import EvaluationState from './Evaluations/EvaluationsState'
import EvaluationsContext from './Evaluations/EvaluationsContext'

//ConfigStates
import ConfigState from './Config/ConfigState';
import ConfigContext from './Config/ConfigContext';

//Loadding States
import LoadingState from './Loading/LoadingState';
import LoadingContext from './Loading/LoadingContext';

//Loadding States
import GridState from './Grid/GridState';
import GridContext from './Grid/GridContext';


const AplicationState = ({ children }) => {
    return (
        <ErrorState>
            <LoadingState>
                <ConfigState>
                    <UserState>
                        <EvaluationState>
                            <GridState>
                                { children }
                            </GridState>
                        </EvaluationState>                
                    </UserState>
                </ConfigState> 
            </LoadingState>
        </ErrorState>
    )
}

const Context  = {
    AplicationState,
    UserContext,
    ErrorContext,
    EvaluationsContext,
    ConfigContext,
    LoadingContext,
    GridContext
};

export default Context;
