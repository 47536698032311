import React, {  useState, useEffect } from 'react'
import EvaluationContext from './EvaluationsContext'

const EvaluationState = ({children}) => {
    const [currentActivity, setCurrentActivity] = useState(0);
    const [currentAssessment, setCurrentAssessment] = useState(0);

    const handleChangeActivity = ()=> {
        setCurrentActivity(currentActivity+1)
    }
    const handleChangeAssessment = ()=> {
        setCurrentAssessment(currentAssessment+1)
    }
    const resetCurrentActivity = ()=> {
        setCurrentActivity(0);
    }


    return (
        <EvaluationContext.Provider 
                value={{
                    currentActivity,
                    onChangeActivity: handleChangeActivity,
                    currentAssessment,
                    onChangeAssessment: handleChangeAssessment,
                    resetCurrentActivity
                }}
            >
                { children }
        </EvaluationContext.Provider>
    )
}

export default EvaluationState;