const home = [
    // Main 
    {
        // height: 8,
        width:9,
        top: 1,
        left: 1,
        border: true,
        fullWidth: true
    },  
    // Video
    {
        height: 6,
        width: 10,
        top: 2,
        left: 11,
        fullWidth: true
    },
     // Button 1
    {
        height: 1,
        width: 3,
        top: 9,
        left: 12,
        fullWidth: true
    },
     // Button 2
    {
        height: 1,
        width: 3,
        top: 9,
        left: 17,
        fullWidth: true
    }
]

export default home