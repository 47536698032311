const teacherTest = [
  // Title Area
  {
    height: 1,
    width: 15,
    top: 0,
    left: 3,
    fullWidth: true,
  },
  // Subtitle trys
  {
    height: 1,
    width: 4,
    top: 0,
    left: 19,
  },
  // Right Box - timer
  {
    width: 3,
    height: 3,
    top: 2,
    left: 19,
    border: true,
    fullWidth: true,
    is_timer: true
  },
  // Image
  {
    height: 9,
    width: 6,
    top: 2,
    left: -1,
    image_sidebar: true,
    // fullWidth: true
  },
  // Evaluation
  {
    height: 8,
    width: 15,
    top: 2,
    left: 3,
    fullWidth: true,
    border: true,
    minHeight: 12,
    minWidth: 9
  },
];

export default teacherTest;
