import { useContext, useEffect, useState } from "react"
import { ELEMENTS_VIEWPORT, POSITION, VIEWPORT } from "../constants"
import Context from "../context"
import { getDistanceFrom, getSizeFromGrid } from "../helpers/tools.helpers"

function useGridLayout(components, logo_position = 'left') {
    const {grid, elements, isHorizontal, handleTotalHeight} = useContext(Context.GridContext)

    const [layout, setLayout] = useState([])

    const handleHorizontal = () => {
        let newLayout = components.map(({
            height, width, top = 1, left = 1,
            centerX = false, centerY = false, 
            fullWidth = false, border = false
        }) => {

            if (centerX && !width) {
                width = ELEMENTS_VIEWPORT.x - 3
                left = 1
                centerX = false
            }
            if (centerY && !height) {
                // height = ELEMENTS_VIEWPORT.y - 2
                top = 1
                centerY = false
            }
            let layoutElement = {
                ...getSizeFromGrid(height ?? (elements.y - top - 1), width ?? (elements.x - left -1), border),
                ...getDistanceFrom(POSITION.LEFT, centerX ? Math.floor((ELEMENTS_VIEWPORT.x - width) / 2) : left, border),
                ...getDistanceFrom(POSITION.TOP, centerY ? Math.floor((elements.y - height) / 2) : top, border),
                position: 'absolute'
            }

            return layoutElement
        })
        return newLayout
    }

    const handleVertical = () => {
        let newLayout = []
        const total_width = elements.x - 2
        const total_height = ELEMENTS_VIEWPORT.y - 2
        let image_element = false

        components.forEach(({
            height, width: width_element, top = 1, left = 1, minHeight = false,
            centerX = false, centerY = false, 
            fullWidth = false, fullHeight = false, border = false,
            display = true, ...element
        }) => {
            let width = width_element ?? 0
            const lastElement = newLayout[newLayout.length -1]
            if ((width < total_width && fullWidth) || width > total_width) width = total_width
            if (!lastElement) width -= 2
            
            const hasImageSidebar = newLayout.some(element => element?.image)

            let top_grids = lastElement 
                ? (lastElement.top_grids + lastElement.height_grids + 1) 
                : top === 0 ? top : 1

            if (hasImageSidebar) {
                let withoutImages = newLayout.filter(e => !e.image)
                let last = withoutImages.splice(-1)[0]
                top_grids = (last.top_grids + last.height_grids + 1)
                width -= image_element.width_grids - 1.5
            }
            
            let height_grids = height ?? total_height

            if (!display) {
                height_grids = 0
                top_grids --
            }

            
            let position = 1
            
            if (logo_position === 'left' && !lastElement) position = 3
            if (logo_position === 'left' && (lastElement?.image || lastElement?.display  === 'none')) position = 3
            
            if (element.image_sidebar) {                
                height_grids = height
                top_grids = top
                width = width_element
                position = left
            } 

            if (minHeight) height_grids = minHeight

            if (centerX) {
                top_grids = Math.floor((elements.y - height_grids) / 2)
            }

            if (centerY) {
                position = Math.floor((elements.x - width) / 2)
                if (position <= 3 && logo_position === 'left') position = 3
            }

            if (element.is_graph) {
                position = 1
                top_grids = 2
                width = ELEMENTS_VIEWPORT.minX - 4
                height_grids = elements.y - 3
            }

            if (element.is_timer) {
                position = 3
                top_grids = 2
            }

            if (element.minWidth) width = element.minWidth
            
            let layoutComponent = {
                ...getSizeFromGrid(height_grids, width, border),
                ...getDistanceFrom(POSITION.TOP, top_grids, border),
                ...getDistanceFrom(POSITION.LEFT, position, border),
                height_grids,
                top_grids,  
                width_grids: width,
                display: display ? 'inherit' : 'none',
                position: 'absolute'
            }
            
            newLayout.push(layoutComponent)
            if (element.image_sidebar) {
                layoutComponent.image = true
                image_element = layoutComponent
            }
        })

        const lastLayoutElement = newLayout[newLayout.length -1]
        const total_layout_height = (lastLayoutElement.top_grids ?? 0) + (lastLayoutElement.height_grids ?? 0) + 1.2
        if (total_layout_height > elements.y && !components.some(element => element.is_graph)) {
            handleTotalHeight(total_layout_height)
        }
        if (total_layout_height < elements.y) {
            handleTotalHeight(total_layout_height)
        }
        return newLayout
    }

    useEffect(() => {
        const newLayout =  window.innerWidth > VIEWPORT ? handleHorizontal() : handleVertical()
        setLayout(newLayout)
    },[grid.factor, elements.x, elements.y, isHorizontal, components])

    return layout ?? []
}

export default useGridLayout