import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { FaRegClock } from 'react-icons/fa';

//Scss
import './TeacherTest.scss';

//Components
import XIfame from '../../components/XIframe/XIfame';
import Context from '../../context';
import useTimer from '../../hooks/useTimer';

import { getDistanceFrom, getSizeFromGrid, secontToClock } from './../../helpers/tools.helpers'
import XBar from '../../components/XBar/XBar';
import RightBox from '../../components/RigthBox/RightBox';
import { POSITION } from '../../constants';
import useGridLayout from '../../hooks/useGridLayout';
import { teacherTest } from '../../layouts';

const assessments = [4, 5]

const WITHOUT_TIME = process.env.REACT_APP_WITHOUT_TIME

const TeacherTest = () => {

    const navigate = useNavigate()
    const { getUserEvaluation, user, evaluation } = useContext(Context.UserContext);
    const { config } = useContext(Context.ConfigContext);
    const { isHorizontal, grid } = useContext(Context.GridContext);
    const { currentAssessment, currentActivity, onChangeActivity, onChangeAssessment } = useContext(Context.EvaluationsContext)

    //States
    const [disabledButton, setDisabledButton] = useState(true);
    const [sendMessagesEnabled, setSendMessagesEnabled] = useState(false);
    const [timeoutAssessment, setTimeoutAssessment] = useState(false);
    const [timeoutActivity, setTimeoutActivity] = useState(false);

    const forceEndActivity = useRef(null);
    const sendClickButton = useRef(null);

    //Timers
    const [timerAssessment, startTimerAssessment, restartTimerAssessment] = useTimer(config.MAX_TIME_ASSESSMENT);
    const [timerActicity, startTimerActicity, restartTimerActicity] = useTimer(config.MAX_TIME_ACTIVITY);

    const [title, subtitle, rightBox, background_image, iframe] = useGridLayout(teacherTest)

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
    
    useEffect(() => {
        if (evaluation.try >= config.MAX_TRYS) {
            navigate('/')
        }
    }, [evaluation?.try, config.MAX_TRYS, navigate])

    useEffect(()=> {
        startTimerAssessment();

        if (timerAssessment <= 120) {
            setTimeoutAssessment(true);
        }

        if(!WITHOUT_TIME && timerAssessment <= 0){
            //End Assessments
            navigate('/final-page');
        }

        if (!WITHOUT_TIME && timerActicity <= 10) {
            setTimeoutActivity(true);
        }

        if(!WITHOUT_TIME && timerActicity <= 0){
            //Send nextActivity
            setSendMessagesEnabled(false);
            forceEndActivity.current(currentActivity);
            return
        }
    }, [
        currentAssessment, timerAssessment,
        startTimerAssessment,sendMessagesEnabled,
        restartTimerAssessment, timerActicity
    ])

    let onEndAssessment = ()=> {
        const currentSuject = evaluation.evaluation[0].name === "initial"? evaluation.evaluation[1] : evaluation.evaluation[0];
        if(currentAssessment+1 >= currentSuject.subjects.length ) {
            navigate('/final-page');
            return;
        }else{
            onChangeAssessment();
        }
    }

    const onStartActivity = ()=> {
        setTimeoutActivity(false);
        setSendMessagesEnabled(true);
        restartTimerActicity();
        startTimerActicity();
        onChangeActivity();
    }

    const onEndActivity= ()=> {    
        setDisabledButton(true);
    }

    const onActivityAnswered = ()=> {
        setDisabledButton(false);
    }

    if(currentAssessment === null && currentAssessment >= assessments.length && !evaluation) return null;
    if(!evaluation) return null;

    const currentSuject = evaluation.evaluation[0].name === "initial"? evaluation.evaluation[1].subjects : evaluation.evaluation[0].subjects
    const intento = evaluation.try
    const max_try = config.MAX_TRYS - 1;
    
    if(!currentSuject[currentAssessment] ) return null
    if (!title) return null;

    const subtitlePos = {
        top: isHorizontal ? subtitle.top : getDistanceFrom(POSITION.TOP, 1, true).top,
        left: isHorizontal ? subtitle.left : getDistanceFrom(POSITION.LEFT, 10, true).left,
    }

    console.log({subtitle, subtitlePos})

    return (
        <div className="container-image-teacher">
            <div style={{
                backgroundImage: `url('/images/women_2.png')`,
                backgroundRepeat:'no-repeat',
                backgroundSize: 'contain',
                ...background_image
            }}></div>

            <div className='teacher-container-activity'>
                {intento && (
                    <h2
                        style={{
                            ...subtitle,
                            ...subtitlePos,
                            lineHeight: `${grid.factor * 2}px`,
                            paddingTop: grid.factor * 2,
                            fontSize: isHorizontal ? `1.5rem` :  `calc(var(--font-size-by-grid) * 1.7)`,
                        }} 
                        className='subtitle-mhe-blue'
                    >{`Intento ${intento} de ${max_try}`}</h2>
                )}
                <h1 
                    style={{
                        ...title,
                        lineHeight: `${grid.factor *2}px`,
                        paddingTop: grid.factor *2,
                        fontSize: isHorizontal ? `2rem` :  `calc(var(--font-size-by-grid) * 2)`
                        // top: getDistanceFrom(POSITION.TOP, 0).top + 14,
                        // left: getDistanceFrom(POSITION.LEFT, 5).left + 14 ,
                    }}
                    className='title-mhe-red'
                >{'Área ' + currentSuject?.[currentAssessment]?.area + ': ' + currentSuject?.[currentAssessment]?.name}</h1>
                <div
                    className="teacher-iframe-activity border-double-mix"
                    style={{
                        ...iframe,
                        // ...getDistanceFrom(POSITION.TOP, 2, true),
                        // ...getDistanceFrom(POSITION.LEFT, 5),
                        // width: size.width,
                        // maxHeight: size.height,
                        // minHeight: getSizeFromGrid(7, 1, true).height,
                        backgroundColor: 'white'
                    }}
                >
                    <XIfame 
                        style={{
                            position: 'relative',
                            width: '100%',  
                            maxHeight: '100%' 
                            // maxHeight: getSizeFromGrid(7, 1, true).height,  
                        }}
                        userName={user.email}
                        assessmentId={currentSuject[currentAssessment].id}
                        title='Teacher test Activity'
                        forceEndActivityFunc={forceEndActivity}
                        nexActivityFunc={sendClickButton}
                        eventsIframe={{
                            'end-assessment': onEndAssessment,
                            "answered-activity": onActivityAnswered,
                            'end-activity': onEndActivity,
                            'start-activity': onStartActivity
                        }}
                    />
                </div>
            </div>
            <RightBox 
                container={rightBox}
                currentActivity={currentActivity} 
                currentTimer={timerActicity} 
                disabledButton={disabledButton} 
                globalTimer={timerAssessment} 
                onClick={sendClickButton.current} 
                totalActivities={60}
            />
        </div>
    )
}


export default TeacherTest;