const logout = [
    // MODAL error
    {
        height: 5,
        width: 16,
        centerX: true,
        centerY: true,
        border: true
    },  
]

export default logout