import React, { useContext, useEffect } from 'react'
import Xbutton from '../../components/XButton/Xbutton'
import XContainerScroll from '../../components/XContanerScroll/XContainerScroll';

import Context from '../../context';
import { getLocalUserToken } from '../../helpers/auth.helpers';

import useGridLayout from '../../hooks/useGridLayout';
import { report } from '../../layouts';

//Scss
import './Report.scss'

const {REACT_APP_SCORM_URI_WITH_FORM, REACT_APP_SCORM_URI_WITHOUT_FORM} = process.env

const Report = () => {
    const {user, showFormInfo} = useContext(Context.UserContext);
    const {getConfig} = useContext(Context.ConfigContext);
    const dashboard_uri = process.env.REACT_APP_DASHBOARD_URI;
    const dashboard_evaluation_uri = process.env.REACT_APP_DASHBOARD_EVALUATION_URI;
    const backUrl = showFormInfo ? REACT_APP_SCORM_URI_WITH_FORM : REACT_APP_SCORM_URI_WITHOUT_FORM;

    const [main, image, button] = useGridLayout(report, 'left')

    useEffect(() => {
        const init = async()=> {
            const lti = getLocalUserToken();
            try {
                const data = await getConfig();
            } catch (error) {
                console.log("error");
            }
        }
        init();
    }, []);

    const handleGoToDashboard = () => {
        const url = `${dashboard_uri}?userId=${user.lti}&toUrl=${dashboard_evaluation_uri}&backUrl=${backUrl}&report=true`;

        return window.open(url, 'blank')
    }

    if (!main) return null

    return (
        <>
            <div className="container-fluid">
                <div className="">
                    <XContainerScroll 
                        className='bg-white border-double-mix'
                        position={{ ...main }}
                        { ...{width: main.width, height: main.height}}
                    >
                        <h2 className="title-mhe-red" >Informe de resultados</h2>
                        <h3 className="subtitle-mhe-blue">McGraw Hill Education</h3>
                        <p>
                            Bienvenido/a a la herramienta de McGraw Hill Education para la evaluación de la competencia digital docente del profesorado.
                        </p>
                        <p>Para acceder a los distintos informes pulse sobre 'Acceder al informe'.</p>
                    </XContainerScroll>
                    <img 
                        alt=''
                        style={{ ...image }}
                        // src="https://images.unsplash.com/photo-1577896851231-70ef18881754?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                        src="/images/aula3.jpg"
                        id="image" 
                    />
                </div>
            </div>
            
            <div className="">
                <Xbutton 
                    style={{ ...button  }} 
                    long={true} 
                    onClick={ ()=> handleGoToDashboard() }
                >Acceder al informe</Xbutton> 
            </div>
        </>
    )
}

export default Report;